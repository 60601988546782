import React from "react";
import { graphql } from "gatsby";

// Layout
import Layout from "../components/Layout";
import Seo from "../components/Seo";

// Tailwind React UI Components
import { Container, Flex, Section, Title, FillButton } from "tailwind-react-ui";

// Custom Components
import SubPageBanner from "../components/SubPageBanner";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseReturn } from "@fortawesome/pro-regular-svg-icons/faHouseReturn";

// Images
import TrianglesGY from "../images/patterns/patterns-triangles_greenyellow.svg";

// GraphQL
export const query = graphql`
  query NotFoundQuery {
    siteInfo: craftSiteInformationGlobalSet {
      ...SiteInfoFragment
    }
    site {
      siteMetadata {
        title
        description
        author
        keywords
        siteUrl
      }
    }
  }
`;

const NotFoundPage = ({ data: { siteInfo, site } }) => {
  const [featureImage] = siteInfo.featureImage;

  return (
    <Layout
      location={`${site.siteMetadata.siteUrl}/404`}
      title={`404 Page Not Found | ` + site.siteMetadata.title}
    >
      <Seo
        title={`404 Page Not Found | ` + site.siteMetadata.title}
        url={`${site.siteMetadata.siteUrl}/404`}
        pathname={`/404`}
      />
      <h1 style={{ fontSize: 0, color: "transparent" }} className="sr-only">
        404 Page not found
      </h1>
      <Section p="0" className="relative">
        <SubPageBanner
          title="404"
          subtitle={siteInfo.siteTitle}
          padding="py-20"
          image={featureImage}
        />
      </Section>

      <Section>
        <Container className="py-14 text-center lg:w-2/3">
          <Flex
            className="relative flex text-center"
            justify="center"
            items="center"
          >
            <Title level={3} text="4xl">
              Oops! A dassie ate the page you're looking&nbsp;for!
            </Title>
            <TrianglesGY className="absolute left-full -top-6" alt="Pattern" />
          </Flex>
          <FillButton
            font="display"
            data-type="button"
            bg="secondary"
            bg-hocus="primary"
            text="primary"
            is="a"
            href="/"
            text-hocus="white"
            className="transition duration-300 ease-in-out px-3 lg:px-8 py-3 rounded-full mx-auto lg:mt-10 mt-5 mb-0 border-secondary focus:border-primary hover:border-primary font-display"
          >
            Return home&nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon color="inherit" size="lg" icon={faHouseReturn} />
          </FillButton>
        </Container>
      </Section>
    </Layout>
  );
};

export default NotFoundPage;
